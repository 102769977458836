var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "wrap"
    },
    [
      _c("h4", { staticClass: "title" }, [_vm._v("通知范围：")]),
      _c(
        "section",
        { staticClass: "section" },
        [_c("el-radio", { attrs: { value: 1, label: 1 } }, [_vm._v("全部")])],
        1
      ),
      _c("h4", { staticClass: "title" }, [_vm._v("通知设置：")]),
      _c(
        "section",
        { staticClass: "section" },
        [
          _c(
            "el-form",
            [
              _c(
                "el-form-item",
                { attrs: { label: "是否强制消息通知：" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.is_force,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "is_force", $$v)
                        },
                        expression: "form.is_force"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("是")]),
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("否")])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "消息类型：" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.type,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "type", $$v)
                        },
                        expression: "form.type"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("系统公告")
                      ]),
                      _c("el-radio", { attrs: { label: 2 } }, [
                        _vm._v("内容更新")
                      ]),
                      _c("el-radio", { attrs: { label: 3 } }, [_vm._v("其他")])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "通知时间：" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.immediate,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "immediate", $$v)
                        },
                        expression: "form.immediate"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("即时通知")
                      ]),
                      _c("el-radio", { attrs: { label: 0 } }, [
                        _vm._v("定时通知")
                      ])
                    ],
                    1
                  ),
                  _vm.form.immediate === 0
                    ? _c("el-date-picker", {
                        staticStyle: { "margin-left": "11px" },
                        attrs: {
                          size: "small",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          type: "datetime",
                          placeholder: "选择通知时间",
                          "picker-options": _vm.pickerOptions
                        },
                        model: {
                          value: _vm.form.start_time,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "start_time", $$v)
                          },
                          expression: "form.start_time"
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("h4", { staticClass: "title" }, [_vm._v("通知内容：")]),
      _c(
        "section",
        { staticClass: "section" },
        [
          _c("p", { staticClass: "title" }, [_vm._v("标题：")]),
          _c("el-input", {
            attrs: {
              type: "textarea",
              rows: 2,
              maxlength: "40",
              "show-word-limit": ""
            },
            model: {
              value: _vm.form.title,
              callback: function($$v) {
                _vm.$set(_vm.form, "title", $$v)
              },
              expression: "form.title"
            }
          }),
          _c("p", { staticClass: "title" }, [_vm._v("内容：")]),
          _c("Editor", {
            staticStyle: { width: "100%" },
            attrs: {
              init: _vm.editorInit,
              height: _vm.editorInit.height,
              showUpload: false
            },
            model: {
              value: _vm.form.content,
              callback: function($$v) {
                _vm.$set(_vm.form, "content", $$v)
              },
              expression: "form.content"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "text-align": "center" } },
        [
          _c(
            "el-button",
            {
              staticStyle: { width: "120px", margin: "15px" },
              attrs: { type: "primary" },
              on: { click: _vm.onConfirm }
            },
            [_vm._v("发送")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }